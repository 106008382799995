@if(!loading){
  <is-igx-organization-chart
    title="役職に顧客を紐づける"
    [orgData]="organizationChart"
    [mappingData]="mappingData"
    [mappingDataHub]="mappingDataHub"
    [hubData]="hubData"
    (onReGisterOrganizationChart)="onReGisterOrganizationChart($event)"
  ></is-igx-organization-chart>
  }
  