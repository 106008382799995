import { Routes } from '@angular/router'
import { CreateStructureComponent } from './views/organization/create-structure/create-structure.component'
import { OrganizationStructureEditComponent } from './views/organization/organization-structure-edit/organization-structure-edit.component'
import { OfficialPositionEditorComponent } from './views/organization/official-position-editor/official-position-editor.component'
import { RegisterOfficialPositionMasterComponent } from './views/organization/register-official-position-master/register-official-position-master.component'
import { LinkOfficialPositionOrganizationComponent } from './views/organization/link-official-position-to-organization/link-official-position-organization.component'
import { AssignCustomerComponent } from './views/organization/assign-customer-to-company/assign-customer.component'
import { LinkCustomerToOfficialPositionComponent } from './views/organization/link-customer-to-official-position/link-customer-to-official-position.component'
import { AssignPersonToCompanyComponent } from './views/organization/assign-person-to-company/assign-person-to-company.component'
import { IsTokenReceiverComponent } from '@interserve/angular'
import { RegisterEmploymentContractForCustomerComponent } from './views/organization/register-employment-contract-for-customer/register-employment-contract-for-customer.component'
import { RegisterEmploymentContractComponent } from './views/organization/register-employment-contract/register-employment-contract.component'

export const routes: Routes = [
  {
    path: 'create/structure',
    component: CreateStructureComponent,
  },
  {
    path: 'create-structure',
    component: CreateStructureComponent,
  },
  {
    path: 'create-structure/:organizationChartId',
    component: CreateStructureComponent,
  },
  {
    path: 'organization-structure/edit/:organizationChartId',
    component: OrganizationStructureEditComponent,
  },
  {
    path: 'office-position/editor',
    component: OfficialPositionEditorComponent,
  },
  {
    path: 'office-position/editor/:organizationChartId',
    component: OfficialPositionEditorComponent,
  },
  {
    path: 'office-position/editor/:organizationChartId/:customerId',
    component: OfficialPositionEditorComponent,
  },
  {
    path: 'office-position/master/editor',
    component: RegisterOfficialPositionMasterComponent,
  },
  {
    path: 'link-official-position-to-organization/:organizationChartId/:organizationId',
    component: LinkOfficialPositionOrganizationComponent,
  },
  //会社に人を紐づける
  {
    path: 'assign-customer-to-company/:organizationChartId/:organizationId',
    component: AssignCustomerComponent,
  },
  // 役職に人を紐づける
  {
    path: 'link-customer-to-official-position/:organizationChartId/:organizationId',
    component: LinkCustomerToOfficialPositionComponent,
  },
  //会社に人を紐づける
  {
    path: 'assign-person-to-company/:organizationChartId/:organizationId',
    component: AssignPersonToCompanyComponent,
  },
  // 雇用契約
  {
    path: 'register-employment-contract/:organizationChartId',
    component: RegisterEmploymentContractComponent,
  },

  // 雇用契約ネームカードVer
  {
    path: 'register-employment-contract-for-customer/:organizationChartId/:customerId',
    component: RegisterEmploymentContractForCustomerComponent,
  },
  {
    path: 'token-receiver',
    component: IsTokenReceiverComponent,
    outlet: 'token',
  },
]
