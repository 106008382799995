@if(!loading){
<is-igx-organization-chart
  title="人を所属させる"
  [mappingData]="mappingData"
  [mappingDataHub]="mappingDataHub"
  [orgData]="organizationChart"
  [hubData]="hubData"
  (onReGisterOrganizationChart)="onReGisterOrganizationChart($event)"
></is-igx-organization-chart>
}
