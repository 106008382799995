import { Component, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterOutlet } from '@angular/router'
import { IsIgxHubComponent } from '@interserve/igx-angular'
import { HttpErrorResponse } from '@angular/common/http'
import { IsTokenService } from '@interserve/angular'
import AuthApiService from './services/apis/authApi.service'
import { environment } from './environments/environment'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, IsIgxHubComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // test
  private testFlg = environment.test
  tokenSetFlg = false
  // 組織編集のキー
  private functionKey = '211d2fcb-7773-4f16-a4e8-a2aa515e7a8d'
  private isOrganizationCd = '1'
  private uraIdeaUrl = environment.ui.backend.uraIdea + '/ura-top'
  constructor(private isTokenService: IsTokenService, private authApiService: AuthApiService) {}
  async ngOnInit(): Promise<void> {
    if (this.testFlg) {
      this.tokenSetFlg = true
      return
    }
    this.subscribeToken()
    const url = location.href
    //url に token-receiverが含まれている場合はのif
    //TODO: ここは使う側に依存するので、考える必要ある。
    // if (url.indexOf('token-receiver') !== -1) {
    //   return
    // }
    // this.authorize()
    this.tokenSetFlg = true
  }
  subscribeToken() {
    this.isTokenService.watchLocalStorageToken().subscribe((token) => {
      setTimeout(async () => {
        await this.authorize()
        this.tokenSetFlg = true
      })
    })
  }

  async authorize() {
    const token = this.isTokenService.getLocalStorageToken()
    if (token) {
      const res = await this.authApiService
        .loginV3ForToken(token)
        .catch((error: HttpErrorResponse) => {
          if (error.status === 401) {
            alert('Sessionが切れました。')

            location.href = this.uraIdeaUrl
            return
          }
        })

      const data = res.data[0]
      const isOrg = data.organizations.find((x: any) => x.organizationCd === this.isOrganizationCd)

      if (!isOrg) {
        // isの組織がない場合リダイレクト
        location.href = this.uraIdeaUrl
        return
      }

      const organizationCd = isOrg.organizationCd
      const customerCd = data.customerCd

      const indexDnsRes = await this.authApiService.indexDns(organizationCd, customerCd)
      const fileSystemId = indexDnsRes.data.fileSystemId
      const wbsManagementRes = await this.authApiService.wbsManagement(fileSystemId, 1)
      const wbsManagementData = wbsManagementRes.data
      const findFunction = wbsManagementData.find((x: any) => x.cellId === this.functionKey)
      if (!findFunction) {
        alert('権限がありません。')
        location.href = this.uraIdeaUrl
        return
      }
      this.tokenSetFlg = true
    } else {
      // tokenがない場合リダイレクト
      location.href = this.uraIdeaUrl
      return
    }
  }
}
