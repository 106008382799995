import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IsIgxOrganizationChartComponent, IsWbsService, WbsFrontend } from '@interserve/igx-angular'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-link-customer-to-official-position',
  standalone: true,
  imports: [CommonModule, IsIgxOrganizationChartComponent],
  templateUrl: './link-customer-to-official-position.component.html',
  styleUrl: './link-customer-to-official-position.component.scss',
})
export class LinkCustomerToOfficialPositionComponent {
  loading: boolean = true
  organizationChart: any[] = []
  hubData: any[] = []
  organizationChartId: string = ''
  organizationId: string = ''
  mappingData: WbsFrontend = {
    layout: {
      leftWbs: {
        type: 'wbs',
      },
      leftWbsRules: {
        primaryKey: 'frontEndId',
        rowDraggable: true,
        nonStructure: true,
      },
      rightWbs: {
        type: 'wbs',
        footer: {
          buttons: [
            {
              key: 'select',
              label: '追加',
              color: 'primary',
              action: 'add',
            },
          ],
        },
      },
      rightWbsRules: {
        primaryKey: 'frontEndId',
        rowDraggable: true,
      },
    },
  }
  mappingDataHub: WbsFrontend | undefined

  apiExecuting: boolean = false
  constructor(
    private route: ActivatedRoute,
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private isWbsService: IsWbsService
  ) {}

  async ngOnInit(): Promise<void> {
    const organizationId = this.route.snapshot.paramMap.get('organizationId')
    this.organizationId = organizationId || ''
    const organizationChartId = this.route.snapshot.paramMap.get('organizationChartId')
    this.organizationChartId = organizationChartId || ''
    this.getOfficePositionOrganizationChart()
    const customerCompanyFlat = await this.getCustomerOrganizationFlat()
    const data = await this.backendOrganizationLeafService.getFrontEndSettingsItem(
      'organization',
      'link-customer-to-official-positionV3'
    )
    this.mappingDataHub = data.data
    this.loading = false
    setTimeout(() => {
      this.hubData = customerCompanyFlat
    }, 0)
  }

  async getOfficePositionOrganizationChart() {
    this.organizationChart = []
    const res = await this.backendOrganizationLeafService.getOfficialPosition(this.organizationId)
    this.organizationChart = [this.isWbsService.addFrontEndId(res.data)]
  }

  async getCustomerOrganizationFlat() {
    const res = await this.backendOrganizationLeafService.getCustomerOrganizationFlat(
      this.organizationId
    )
    // ret.dataのprofile.levelCdに-1を追加する
    const addMinusLevelCd = res.data.map((item: any) => {
      item.profiles.levelCd = -1
      return item
    })
    return addMinusLevelCd
  }

  async onReGisterOrganizationChart({ addData, orgData }: any) {
    // api実行中は何もしない
    if (this.apiExecuting) {
      return
    }
    this.apiExecuting = true
    const ret = await this.backendOrganizationLeafService.postCustomerOfficialPosition(
      this.organizationId,
      orgData[0]
    )
    this.apiExecuting = false
    if (!ret.resultCd) {
      alert('登録しました')
      this.getOfficePositionOrganizationChart()
    }
  }
}
