import { Component, OnInit, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import {
  IsIgxDialogComponent,
  IsIgxHubComponent,
  IsIgxOrganizationChartComponent,
  IsWbsService,
  WbsFrontend,
} from '@interserve/igx-angular'
import {
  templateHub,
  templateFormHub,
  organizationChartSetting,
  organizationChartHubSetting,
} from './static/mappingData'
import { UraIdeaHeaderComponent } from '../components/uraIdeaHeader/ura-idea-header.component'
import { IgxButtonModule } from '@infragistics/igniteui-angular'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { environment } from '../../../environments/environment'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'

@Component({
  selector: 'app-create-structure',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IsIgxHubComponent,
    IsIgxDialogComponent,
    IsIgxOrganizationChartComponent,
    UraIdeaHeaderComponent,
    IgxButtonModule,
    RouterModule,
  ],
  templateUrl: './create-structure.component.html',
  styleUrl: './create-structure.component.scss',
})
export class CreateStructureComponent implements OnInit {
  ngOnInit(): void {
    const organizationChartId = this.route.snapshot.paramMap.get('organizationChartId')
    if (organizationChartId) {
      this.selectedOrganization = { organizationId: organizationChartId }
      this.createOrganizationInit()
    }
  }
  constructor(
    private isWbsService: IsWbsService,
    private backendOrganizationService: BackendOrganizationLeafService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  loadingOrganization = false
  organizationName = ''
  selectedOrganization: any | null
  organizations: any[] = []
  organizationChart: any[] = []
  test? = environment.test
  createdStructureFlg = false
  registerOrganizationStructureFlg = false

  structures: any[] = [
    {
      id: `${crypto.randomUUID()}`,
      name: '会社',
      wbsType: 'company',
      profiles: {
        editable: false,
        typeCd: 'company',
      },
    },
  ]

  addPlusButtonObj(list: any[]) {
    //plusButtonがある場合は一度削除する
    list = this.removePlusButtonObj(list)
    //最後尾にplusButtonを追加するIDはランダムなUUIDを生成する
    const plusButtonObj = {
      name: '',
      id: crypto.randomUUID(),
      wbsType: 'plusButton',
      plusFlg: true,
    }
    list.push(plusButtonObj)
    return list
  }

  removePlusButtonObj(list: any[]) {
    //plusButtonがある場合は一度削除する
    list = list.filter((obj) => obj.wbsType !== 'plusButton')
    return list
  }

  // #region wbs & hub
  @ViewChild('hub', { static: false })
  public hub: IsIgxHubComponent | undefined

  @ViewChild('customTemplate', { static: false })
  public customTemplate: IsIgxHubComponent | undefined

  @ViewChild('form', { static: false })
  public form: IsIgxDialogComponent | undefined

  // TODO:なんとかする。
  @ViewChild('aaaa', { static: false })
  public organizationChartComponent: IsIgxOrganizationChartComponent | undefined

  /**
   * 選択されたテンプレートID
   */
  public mappingData: WbsFrontend | undefined = templateHub
  public templateFormHub: WbsFrontend | undefined = templateFormHub
  public organizationChartHubSetting: WbsFrontend | undefined = organizationChartHubSetting
  public organizationChartSetting: WbsFrontend | undefined = organizationChartSetting
  wbsStaticData: any[] = []
  templateHubActions = {
    select: async (event: any) => {
      const selectedTemplateId = event.leftWbs.selected[0].id
      if (selectedTemplateId === 'template_custom') {
        this.initStructure()
        this.structures = this.addPlusButtonObj(this.structures)
        this.customTemplate?.open()
      } else if (selectedTemplateId === '') {
        alert('テンプレートを選択してください。')
      } else {
        const data = await this.searchTemplateDetail(selectedTemplateId)
        this.addUiDataStructures(data)
        data.forEach((structure: any) => {
          structure.disabled = true
        })
        this.createdStructures = data
        this.createdStructureFlg = true
        this.hub?.close()
      }
    },
  }
  templateFormHubActions = {
    onClickPlus: (event: any) => {
      this.form?.open()
    },
    leftWbsLeftFooterAction: (event: any) => {
      this.customTemplate?.close()
    },
    select: (event: any) => {
      const leftWbsSelectedData = event.leftWbs.selected
      if (leftWbsSelectedData === 0) {
        alert('追加するWBSを選択してください。')
        return
      }
      this.createdStructures = this.addCompany(leftWbsSelectedData)
      this.createdStructureFlg = true
      // this.registerOrganizationStructureFlg = true
      this.initCreatedStructure(this.createdStructures)
      this.hub?.close()
      this.customTemplate?.close()
    },
  }
  // #endregion
  addWbsName = ''

  //#region API Get Series
  private async searchOrganizationStructure() {
    const json = await this.backendOrganizationService.getOrganizationStructure(
      this.selectedOrganization.organizationId
    )
    return json.data
  }
  searchOrganization() {
    this.organizations = []
    if (this.loadingOrganization) {
      return
    }
    this.loadingOrganization = true
    setTimeout(async () => {
      this.loadingOrganization = false
      this.selectedOrganization = null
      console.log('loadingOrganization', this.loadingOrganization)
      const myideaMacro = environment.api.myidea.macroApi
      const url = myideaMacro + '/api/organizationCompany/likeSearch'
      // fetch post
      const promise = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          organizationName: this.organizationName,
        }),
      })
      const json = await promise.json()
      this.organizations = json.data
    }, 2000)
  }
  private async searchTemplate() {
    const json = await this.backendOrganizationService.getTemplate('structureTemplateList')
    return json.data
  }
  private async searchTemplateDetail(templateId: string) {
    const json = await this.backendOrganizationService.getTemplateDetail(templateId)
    return json.data
  }

  private async searchOrganizationOriginalChart() {
    const json = await this.backendOrganizationService.getOrganizationChart(
      this.selectedOrganization.organizationId
    )
    return json.data
  }
  //#endregion

  // #region API Post Series
  /**
   *
   * @returns
   * @description 会社別の組織構成を登録する
   * @example
   **/
  private async registerOrganizationStructure() {
    if (this.createdStructures.length === 0) {
      alert('登録する組織構成がありません。')
      return
    }
    const backendOrganizationLeaf = environment.api.backend.organizationLeafApi
    const url = `${backendOrganizationLeaf}/api/organizationStructure/${this.selectedOrganization.organizationId}`
    const promise = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(this.createdStructures),
    })
    const json = await promise.json()
    return json.data
  }
  // #endregion
  private addCompany(data: any[]) {
    //会社を一番上に追加する
    data.unshift({
      id: `${crypto.randomUUID()}`,
      name: '会社',
      wbsType: 'company',
      profiles: {
        editable: false,
        typeCd: 'company',
      },
    })
    return data
  }
  initStructure() {
    this.structures = [
      {
        id: `${crypto.randomUUID()}`,
        name: '会社',
        wbsType: 'company',
        profiles: {
          editable: false,
          typeCd: 'company',
        },
      },
    ]
  }
  onSelectOrganizationAction() {
    this.router.navigate(['/create-structure', this.selectedOrganization.organizationId])
  }
  async createOrganizationInit() {
    const data = await this.searchOrganizationStructure()
    // this.router.navigate(['/create-structure', this.selectedOrganization.organizationId])
    if (this.organizationChartComponent) {
      this.organizationChartComponent.structuresTest = data
    }
    this.structures = data
    if (this.structures.length === 0) {
      this.openCreateHub()
    } else {
      this.createdStructureFlg = true
      this.registerOrganizationStructureFlg = true
      this.initCreatedStructure(this.structures)
      await this.setOrganizationChart()
    }
  }
  async migrationOrganizationStructure() {
    const organizationChartId = this.selectedOrganization.organizationId
    const data = await this.backendOrganizationService.postMigrationOrganizationStructure(
      organizationChartId
    )
    if (data.resultCode === 0) {
      alert('移行がしました。')
    } else {
      alert(data.data)
    }
  }
  checkDisabled(item: any) {
    //disabledがない場合はtrueを返す
    // disabledがある場合はその値を返す
    return item.disabled ?? true
  }

  private async openCreateHub() {
    const template = await this.searchTemplate()
    this.wbsStaticData = template
    this.structures = this.addPlusButtonObj(this.structures)
    this.hub?.open()
  }

  addStructure() {
    this.structures.push({
      id: `${crypto.randomUUID()}`,
      name: this.addWbsName,
      profiles: null,
    })
    this.structures = this.addPlusButtonObj(this.structures)
    this.addWbsName = ''
    this.form?.close()
  }

  createdStructures: any[] = []

  initCreatedStructure(createdStructures: any[]) {
    // this.createdStructures = this.removePusButtonObj(this.structures)
    // disable を 全て true にする
    const data = this.addUiDataStructures(structuredClone(createdStructures))
    createdStructures.forEach((structure) => {
      structure.disabled = true
    })

    this.createdStructures = data
  }
  orgChartAfterViewInitAction() {
    setTimeout(() => {
      if (this.organizationChartComponent) {
        this.organizationChartComponent.structuresTest = this.createdStructures
      }
      console.log('orgChartAfterViewInitAction', this.organizationChartComponent)
    }, 0)
  }

  async registerOrganizationStructureAction() {
    if (confirm('組織構成を登録しますか？') === false) {
      return
    }
    const data = await this.registerOrganizationStructure()
    this.createdStructures = data
    this.createdStructureFlg = true
    this.registerOrganizationStructureFlg = true
    this.initCreatedStructure(this.createdStructures)

    await this.setOrganizationChart()
  }

  async setOrganizationChart() {
    this.organizationChart = []
    const data = await this.searchOrganizationOriginalChart()
    if (!data) {
      return
    }
    this.organizationChart = [data]
    this.organizationChart = this.isWbsService.addLevelCd(
      structuredClone(this.organizationChart),
      this.createdStructures,
      'levelCd'
    )
    this.organizationChart = this.isWbsService.addFrontEndId(
      structuredClone(this.organizationChart),
      'nodeObjects'
    )
    this.organizationChart = this.isWbsService.addDummyNodes(
      structuredClone(this.organizationChart),
      'nodeObjects',
      'levelCd'
    )

    // this.organizationChart = [addedFrontEndIdData]
  }

  private addUiDataStructures(structures: any[]) {
    const startX = 0
    const endX = 305
    const startY = 40
    const endY = 9999 // とりあえず大きい数字
    const addStartX = 300
    const addEndX = 300
    const addStartY = 0
    const addEndY = 0
    structures.forEach((structure, index) => {
      structure.startX = startX + addStartX * index
      structure.endX = endX + addEndX * index
      structure.startY = startY + addStartY * index
      structure.endY = endY + addEndY * index
      structure.levelCd = index
    })
    return structures
  }

  async registerOrganizationChart(orgData: any[]) {
    const result = await this.backendOrganizationService.postOrganizationChart(orgData)
    return result
  }
  async registerOrganizationChartAction({ addData, orgData }: any) {
    // addData内にidが存在するものがある場合はエラー
    const addDataIds = addData.filter((item: any) => item.id)
    if (addDataIds.length > 0) {
      alert('未配置に登録済みのデータが存在します。')
      return
    }

    if (confirm('組織図を登録します。よろしいですか？')) {
      const rmDummyData = this.isWbsService.removeDummyNodes(
        structuredClone(orgData),
        'nodeObjects'
      )
      const addOrderNoData = this.isWbsService.addOrderNumbers(rmDummyData, 'nodeObjects')
      const result = await this.registerOrganizationChart(addOrderNoData)
      if (result.resultCode === 0) {
        alert('登録しました。')
        // 組織を再取得する
        await this.setOrganizationChart()
      }
    }
  }
}
