import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { lastValueFrom } from 'rxjs'
import { FrontEndSettingItem } from '@interserve/igx-angular'
@Injectable({
  providedIn: 'root',
})
export class BackendOrganizationLeafService {
  private apiUrl = environment.api.backend.organizationLeafApi

  constructor(private http: HttpClient) {}

  /**
   * 組織階層を登録する
   * @param organizationChartId
   * @param data
   * @returns
   * */
  postOrganizationStructure(organizationChartId: string, data: any) {
    return lastValueFrom(
      this.http.post<any>(`${this.apiUrl}/api/organizationStructure/${organizationChartId}`, data)
    )
  }

  /**
   * 組織階層を更新する
   * @deprecated
   * @param organizationChartId
   * @param data
   * @description このAPIは使用しない -postOrganizationStructureDeleteInsertを使用する
   * @returns
   **/
  postOrganizationStructureUpsert(organizationChartId: string, data: any) {
    return lastValueFrom(
      this.http.post<any>(
        `${this.apiUrl}/api/organizationStructure/upsert/${organizationChartId}`,
        data
      )
    )
  }

  /**
   * 組織階層を更新する
   * @param organizationChartId
   * @param data
   * @returns
   **/
  postOrganizationStructureDeleteInsert(organizationChartId: string, data: any) {
    return lastValueFrom(
      this.http.post<any>(
        `${this.apiUrl}/api/organizationStructure/deleteInsert/${organizationChartId}`,
        data
      )
    )
  }

  /**
   * 組織構成を取得する
   * @param organizationChartId
   * @returns
   */
  getOrganizationStructure(organizationChartId: string): Promise<any> {
    return lastValueFrom(
      this.http.get<any>(`${this.apiUrl}/api/organizationStructure/${organizationChartId}`)
    )
  }

  getOrganizationChart(organizationChartId: string) {
    /* return this.http.get(
      `${this.apiUrl}/api/organizationChart/original/search?organizationChartId=${organizationChartId}`
    ) */
    return lastValueFrom(
      this.http.get<any>(
        `${this.apiUrl}/api/organizationChart/original/search?organizationChartId=${organizationChartId}`
      )
    )
  }

  postOrganizationChart(prgData: any) {
    const url = `${this.apiUrl}/api/organizationChart/original`
    return lastValueFrom(this.http.post<any>(url, prgData))
  }

  /**
   * テンプレート一覧を取得する
   * @param settingCd
   * @returns
   **/
  getTemplate(settingCd: string): Promise<any> {
    const url = `${this.apiUrl}/api/organizationStructure/templateList?settingCd=${settingCd}`
    return lastValueFrom(this.http.get<any>(url))
  }

  /**
   * テンプレート詳細を取得する
   * @param templateId
   * @returns
   */
  getTemplateDetail(templateId: string): Promise<any> {
    const url = `${this.apiUrl}/api/organizationStructure/templateDetail?settingCd=${templateId}`
    return lastValueFrom(this.http.get<any>(url))
  }

  //#region official position
  getOfficialPositionTemplate(settingCd: string): Promise<any> {
    const url = `${this.apiUrl}/api/officialPosition/templateList?settingCd=${settingCd}`
    return lastValueFrom(this.http.get<any>(url))
  }

  getOfficialPositionTemplateDetail(templateId: string): Promise<any> {
    const url = `${this.apiUrl}/api/officialPosition/templateDetail?settingCd=${templateId}`
    return lastValueFrom(this.http.get<any>(url))
  }

  /**
   * 役職一覧を取得する
   * @param organizationChartId-会社のID
   * @returns
   **/
  getOfficialPositionList(organizationChartId: string): Promise<any> {
    const url = `${this.apiUrl}/api/officialPosition/List/${organizationChartId}`
    return lastValueFrom(this.http.get<any>(url))
  }

  getOfficialPosition(organizationChartId: string): Promise<any> {
    const url = `${this.apiUrl}/api/officialPosition/${organizationChartId}`
    return lastValueFrom(this.http.get<any>(url))
  }
  /**
   * 役職を登録する
   * @param organizationChartId
   * @param data
   * @returns
   * @deprecated - postOfficialPositionDeleteInsertを使用する
   **/
  postOfficialPosition(organizationChartId: string, data: any) {
    const url = `${this.apiUrl}/api/officialPosition/${organizationChartId}`
    return lastValueFrom(this.http.post<any>(url, data))
  }

  postOfficialPositionDeleteInsert(organizationChartId: string, data: any) {
    const url = `${this.apiUrl}/api/officialPosition/deleteInsert/${organizationChartId}`
    return lastValueFrom(this.http.post<any>(url, data))
  }

  postOfficialPositionRelationUpdate(organizationId: string, data: any): Promise<any> {
    const url = `${this.apiUrl}/api/officialPosition/relation/update/${organizationId}`
    return lastValueFrom(this.http.post<any>(url, data))
  }

  //#endregion

  //#region front end settings

  getFrontEndSettings(indexAppCd: string) {
    const url = `${this.apiUrl}/api/frontEndSettings/${indexAppCd}`
    return lastValueFrom(this.http.get<any>(url))
  }

  postFrontEndSettings(indexAppCd: string, data: any) {
    const url = `${this.apiUrl}/api/frontEndSettings/${indexAppCd}`
    return lastValueFrom(this.http.post<any>(url, data))
  }

  getFrontEndSettingsItem(indexAppCd: string, frontEndCd: string) {
    const url = `${this.apiUrl}/api/frontEndSettings/${indexAppCd}/${frontEndCd}`
    return lastValueFrom(this.http.get<any>(url))
  }

  postFrontEndSettingsItem(indexAppCd: string, frontEndCd: string, data: any) {
    const url = `${this.apiUrl}/api/frontEndSettings/${indexAppCd}/${frontEndCd}`
    return lastValueFrom(this.http.post<FrontEndSettingItem>(url, data))
  }

  //#endregion

  //#region customer
  getCustomerCompany(organizationChartId: string): Promise<any> {
    const url = `${this.apiUrl}/api/customer/company/${organizationChartId}`
    return lastValueFrom(this.http.get<any>(url))
  }

  getCustomerCompanyFlat(organizationChartId: string): Promise<any> {
    const url = `${this.apiUrl}/api/customer/company/${organizationChartId}/flat`
    return lastValueFrom(this.http.get<any>(url))
  }
  getCustomerContractEmployment(organizationChartId: string): Promise<any> {
    const url = `${this.apiUrl}/api/customer/contractEmployment/${organizationChartId}`
    return lastValueFrom(this.http.get<any>(url))
  }
  getCustomerOrganization(organizationId: string): Promise<any> {
    const url = `${this.apiUrl}/api/customer/${organizationId}`
    return lastValueFrom(this.http.get<any>(url))
  }
  getCustomerOrganizationFlat(organizationId: string): Promise<any> {
    const url = `${this.apiUrl}/api/customer/${organizationId}/flat`
    return lastValueFrom(this.http.get<any>(url))
  }

  postCustomerCompany(data: any): Promise<any> {
    const url = `${this.apiUrl}/api/customer/company`
    return lastValueFrom(this.http.post<any>(url, data))
  }
  postCustomerOrganization(organizationId: string, data: any): Promise<any> {
    const url = `${this.apiUrl}/api/customer/${organizationId}`
    return lastValueFrom(this.http.post<any>(url, data))
  }

  postCustomerOfficialPosition(organizationId: string, data: any): Promise<any> {
    const url = `${this.apiUrl}/api/customer/officialPosition/${organizationId}`
    return lastValueFrom(this.http.post<any>(url, data))
  }
  //#endregion

  //#region customer

  //#endregion

  //#region migration
  postMigrationOrganizationStructure(organizationChartId: string) {
    return lastValueFrom(
      this.http.post<any>(
        `${this.apiUrl}/api/migration/organizationStructure/grant/default/${organizationChartId}`,
        {}
      )
    )
  }

  postMigrationContractEmployment(organizationChartId: string) {
    return lastValueFrom(
      this.http.post<any>(
        `${this.apiUrl}/api/migration/contractEmployment/create/default/${organizationChartId}`,
        {}
      )
    )
  }
  //#endregion

  //# region contractEmployment - 雇用契約
  /**
   * 雇用契約情報を取得する
   * @param companyId-会社のID
   * @returns
   **/
  getContractEmployment(companyId: string): Promise<any> {
    const url = `${this.apiUrl}/api/contractEmployment/${companyId}`
    return lastValueFrom(this.http.get<any>(url))
  }
  postContractEmployment(data: any, creatorId?: string): Promise<any> {
    const url = `${this.apiUrl}/api/contractEmployment/create${
      creatorId ? `?creatorId=${creatorId}` : ''
    }`
    return lastValueFrom(this.http.post<any>(url, data))
  }
}
