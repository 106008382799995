import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { IsIgxOrganizationChartComponent, IsWbsService, WbsFrontend } from '@interserve/igx-angular'

@Component({
  selector: 'app-register-employment-contract-',
  standalone: true,
  imports: [CommonModule, IsIgxOrganizationChartComponent],
  template: `@if(!loading){
    <is-igx-organization-chart
      title="人を所属させる"
      [mappingData]="mappingData"
      [mappingDataHub]="mappingDataHub"
      [orgData]="organizationChart"
      [hubData]="hubData"
      [hubParamData]="hubParamData"
      (onReGisterOrganizationChart)="onReGisterOrganizationChart($event)"
    ></is-igx-organization-chart>
    } `,
  styleUrl: './register-employment-contract-for-customer.component.css',
})
export class RegisterEmploymentContractForCustomerComponent {
  loading: boolean = true
  apiExecuting: boolean = false
  organizationChart: any[] = []
  /**
   * 顧客ID - NameCardの検索対象
   */
  customerId?: string
  hubData?: any[]
  organizationChartId: string = ''
  organizationId: string = ''
  mappingData: WbsFrontend | undefined
  mappingDataHub: WbsFrontend | undefined
  hubParamData: { customerId: string; getCard: boolean; createCache: boolean; filterObj: {} } = {
    customerId: '',
    getCard: true,
    createCache: true,
    filterObj: {},
  }
  constructor(
    private route: ActivatedRoute,
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private isWbsService: IsWbsService
  ) {}

  async ngOnInit(): Promise<void> {
    const organizationChartId = this.route.snapshot.paramMap.get('organizationChartId')
    this.organizationChartId = organizationChartId || ''

    const customerId = this.route.snapshot.paramMap.get('customerId')
    // 顧客IDの存在チェック
    if (!customerId) {
      alert('顧客IDが存在しません')
      return
    }
    this.customerId = customerId
    // hubParamDataの設定
    this.hubParamData.customerId = customerId
    this.getContractEmployment()

    const data = await this.backendOrganizationLeafService.getFrontEndSettingsItem(
      'organization',
      'register-employment-contract-customer-hubV3'
    )
    this.mappingDataHub = data.data
    this.mappingData = {
      layout: {
        leftWbs: {
          type: 'wbs',
        },
        leftWbsRules: {
          primaryKey: 'frontEndId',
          rowDraggable: true,
          nonStructure: true,
        },
        rightWbs: {
          type: 'wbs',
          footer: {
            buttons: [
              {
                key: 'select',
                label: '追加',
                color: 'primary',
                action: 'add',
              },
            ],
          },
        },
        rightWbsRules: {
          primaryKey: 'frontEndId',
          rowDraggable: true,
        },
      },
    }
    this.loading = false
  }

  async getContractEmployment(): Promise<any> {
    this.organizationChart = []
    const ret = await this.backendOrganizationLeafService.getContractEmployment(
      this.organizationChartId
    )
    this.organizationChart = [this.isWbsService.addFrontEndId(ret.data)]
  }

  async onReGisterOrganizationChart({ addData, orgData }: any) {
    // api実行中は何もしない
    if (this.apiExecuting) {
      return
    }
    this.apiExecuting = true
    const ret = await this.backendOrganizationLeafService.postContractEmployment(orgData[0])
    if (!ret.resultCd) {
      alert('登録しました')
      this.getContractEmployment()
    }
    this.apiExecuting = false
  }
}
