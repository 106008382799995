<div class="edit-area">
  <app-editable-text-field
    [addItemFlg]="true"
    #editField
    [items]="createdOfficialPositionData"
  ></app-editable-text-field>

  <div class="footer">
    <button igxButton="outlined" (click)="onCancel()">キャンセル</button>
    <button igxButton="outlined" (click)="onOfficialPositionCreated()">登録</button>
  </div>
</div>
<is-igx-hub
  *ngIf="loading"
  #hub
  [leftWbsData]="officialPositionTemplateList"
  [mappingData]="hubSetting"
  [actions]="templateHubActions"
></is-igx-hub>

<is-igx-hub
  *ngIf="loading"
  #customHub
  [actions]="templateFormHubActions"
  [mappingData]="templateHubSetting"
  [leftWbsData]="customHubDataList"
></is-igx-hub>

<is-igx-dialog #form>
  <header>WBSの追加</header>
  <input type="text" [(ngModel)]="addWbsName" />
  <div>
    <button igxButton="outlined" (click)="form.close()">キャンセル</button>
    <button igxButton="outlined" (click)="addStructure()">追加</button>
  </div>
</is-igx-dialog>
