import { WbsFrontend } from '@interserve/igx-angular'

export const templateHub: WbsFrontend = {
  layout: {
    header: {
      label: 'テンプレートを選択',
    },
    footer: {
      buttons: [
        {
          key: 'cancel',
          label: 'キャンセル',
          color: 'cancel',
          disable: false,
          action: 'close',
        },
        {
          key: 'select',
          name: 'save-button',
          label: '選択',
          color: 'primary',
          disable: false,
          action: 'select',
        },
      ],
    },
    leftWbs: {
      type: 'grid',
    },
    leftWbsRules: {
      childDataKey: 'children',
      primaryKey: 'id',
      hideHeader: true,
      rowSelection: 'single',
      noSelectionWbsTypes: ['plusButton'],
      height: '300px',
      width: '100%',
      fields: [
        {
          name: 'name',
          label: '名称',
          width: '100%',
          dataType: 'string',
        },
      ],
    },
  },
}

export const templateFormHub: WbsFrontend = {
  layout: {
    header: {
      label: '要素を追加',
    },
    footer: {
      buttons: [
        {
          key: 'cancel',
          label: 'キャンセル',
          color: 'cancel',
          disable: false,
          action: 'close',
        },
        {
          key: 'select',
          name: 'save-button',
          label: '選択',
          color: 'primary',
          disable: false,
          action: 'select',
        },
      ],
    },
    leftWbs: {
      type: 'grid',
    },
    leftWbsRules: {
      childDataKey: 'children',
      primaryKey: 'id',
      hideHeader: false,
      rowDraggable: true,
      rowSelection: 'multiple',
      noSelectionWbsTypes: ['plusButton', 'company'],
      height: '300px',
      width: '100%',
      fields: [
        {
          name: 'name',
          label: '名称',
          width: '100%',
          dataType: 'string',
        },
      ],
      actionRules: {
        init: [
          {
            event: 'loadLeftStaticData',
          },
        ],
      },
    },
  },
}

export const organizationChartSetting: WbsFrontend = {
  layout: {
    leftWbs: {
      type: 'wbs',
    },
    leftWbsRules: {
      primaryKey: 'frontEndId',
      rowDraggable: true,
    },
    rightWbs: {
      type: 'wbs',
      footer: {
        buttons: [
          {
            key: 'select',
            label: '追加',
            color: 'primary',
            action: 'add',
          },
        ],
      },
    },
    rightWbsRules: {
      primaryKey: 'frontEndId',
      rowDraggable: true,
    },
  },
}

export const organizationChartHubSetting: WbsFrontend = {
  layout: {
    header: {
      label: '組織を追加',
    },
    footer: {
      buttons: [
        {
          key: 'cancel',
          label: 'キャンセル',
          color: 'cancel',
          action: 'close',
        },
        {
          key: 'select',
          name: 'save-button',
          label: '選択',
          color: 'primary',
          action: 'select',
        },
      ],
    },
    leftWbs: {
      type: 'grid',
    },
    leftWbsRules: {
      childDataKey: 'nodeObject',
      primaryKey: 'frontEndId',
      hideHeader: false,
      rowSelection: 'multiple',
      noSelectionWbsTypes: ['plusButton'],
      canAddElement: true,
      fields: [
        {
          name: 'name',
          label: '名称',
          dataType: 'string',
        },
      ],
    },
  },
}
