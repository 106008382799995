import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'

@Component({
  selector: 'app-editable-text-field',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './editable-text-field.component.html',
  styleUrl: './editable-text-field.component.scss',
})
export class EditableTextFieldComponent {
  @Input() items: {
    id: string
    name: string
    disabled?: boolean
    profiles?: {
      editable: boolean
    }
  }[] = []
  @Input() addItemFlg: boolean = false
  checkDisabled(item: any) {
    //disabledがない場合はtrueを返す
    // disabledがある場合はその値を返す
    return item.disabled ?? true
  }
  onDubClick(item: any) {
    item.disabled = false
  }
  validate() {
    let valid = true
    // itemsのnameが空の場合はfalseを返す
    this.items.forEach((item) => {
      if (!item.name) {
        valid = false
      }
    })
    return valid
  }
  onAddButton(item: any, index: number) {
    this.items.splice(index + 1, 0, { id: '', name: '', disabled: true })
  }
}
