import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { environment } from '../../../../environments/environment'
@Component({
  selector: 'app-organization-selected-form',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './organization-selected-form.component.html',
  styleUrl: './organization-selected-form.component.scss',
})
export class OrganizationSelectedFormComponent {
  loadingOrganization = false
  organizations: any[] = []
  inputOrganizationName = ''
  @Input() selectedValue: any = null
  @Output() selectedValueChange = new EventEmitter<any>()

  searchOrganization() {
    this.organizations = []
    if (this.loadingOrganization) {
      return
    }
    this.loadingOrganization = true
    setTimeout(async () => {
      this.loadingOrganization = false
      this.selectedValue = null
      console.log('loadingOrganization', this.loadingOrganization)
      const url =
      `${environment.api.myidea.macroApi}/api/organizationCompany/likeSearch`
      // fetch post
      const promise = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          organizationName: this.inputOrganizationName,
        }),
      })
      const json = await promise.json()
      this.organizations = json.data
    }, 2000)
  }

  onOrganizationSelectChange(newValue: any) {
    this.selectedValueChange.emit(newValue)
  }
}
