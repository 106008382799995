<div class="organization-form-area">
  <div class="organization-form">
    <div>①会社名を入力してください</div>
    <input
      class="organization-input-box"
      type="text"
      placeholder="検索したい組織名を入力"
      (change)="searchOrganization()"
      [(ngModel)]="inputOrganizationName"
    />
  </div>
  <div class="organization-form">
    <div>②プルダウンをクリックし、会社を選択して実行ボタンをしてください</div>
    <select
      class="organization-select-box"
      [ngModel]="selectedValue"
      (ngModelChange)="onOrganizationSelectChange($event)"
    >
      @if (loadingOrganization) {
      <option class="organization-select-box__options">Loading...</option>
      } @else { @if (organizations.length === 0) {
      <option class="organization-select-box__options">組織が見つかりませんでした</option>
      } @else {
      <option class="organization-select-box__options" value="">未選択</option>
      } }
      <option *ngFor="let organization of organizations" [ngValue]="organization">
        {{ organization.organizationName + ':' + organization.corporateNumber }}
      </option>
    </select>
  </div>
</div>
