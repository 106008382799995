<div style="padding: 1vh">
  <section>
    <ura-idea-header></ura-idea-header>
  </section>
  <section style="margin-top: 48px">
    @if (!createdStructureFlg ){
    <div>
      @if(test){
      <div>
        <span>{{ selectedOrganization | json }}</span>
      </div>
      }
      <div class="organization-form-area">
        <div class="organization-form">
          <div>①会社名を入力してください</div>
          <input
            class="organization-input-box"
            type="text"
            placeholder="検索したい組織名を入力"
            (change)="searchOrganization()"
            [(ngModel)]="organizationName"
          />
        </div>
        <div class="organization-form">
          <div>②プルダウンをクリックし、会社を選択して実行ボタンをしてください</div>
          <select class="organization-select-box" [(ngModel)]="selectedOrganization">
            @if (loadingOrganization) {
            <option class="organization-select-box__options">Loading...</option>
            } @else { @if (organizations.length === 0) {
            <option class="organization-select-box__options">組織が見つかりませんでした</option>
            } @else {
            <option class="organization-select-box__options" value="">未選択</option>
            } }
            <option *ngFor="let organization of organizations" [ngValue]="organization">
              {{ organization.organizationName + ':' + organization.corporateNumber }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="organization-execution">
      @if (selectedOrganization) {
      <div style="display: flex; gap: 1rem">
        <button igxButton="outlined" (click)="onSelectOrganizationAction()">実行</button>
        <button igxButton="outlined" (click)="migrationOrganizationStructure()">階層移行</button>
      </div>

      }
    </div>
    } @else if (createdStructureFlg && !registerOrganizationStructureFlg){
    <div class="structures-edit-area">
      @for (item of createdStructures; track $index) {
      <div>
        @if(item.profiles?.editable===false){
        <input style="cursor: not-allowed" disabled type="text" [value]="item.name" />
        } @else {
        <input
          type="text"
          [(ngModel)]="item.name"
          [disabled]="checkDisabled(item)"
          (dblclick)="item.disabled = false"
          (blur)="item.disabled = true"
        />}
      </div>
      }
    </div>
    @if(structures.length > 0){
    <div>
      <button (click)="registerOrganizationStructureAction()">組織構成登録</button>
    </div>
    } } @else if(registerOrganizationStructureFlg){
    <is-igx-organization-chart
      #aaaa
      [structures]="createdStructures"
      [orgData]="organizationChart"
      [mappingData]="organizationChartSetting"
      [mappingDataHub]="organizationChartHubSetting"
      (afterViewInit)="orgChartAfterViewInitAction()"
      (onReGisterOrganizationChart)="registerOrganizationChartAction($event)"
    >
      <div class="title-side-area" style="margin-left: 10px">
        <button
          [routerLink]="['/organization-structure/edit/', selectedOrganization.organizationId]"
          igxButton="outlined"
        >
          階層を編集する
        </button>
      </div>
    </is-igx-organization-chart>
    }
  </section>

  @if(test){
  <pre>{{ organizationChart | json }}</pre>
  }
</div>
<div>
  <is-igx-hub
    [mappingData]="mappingData"
    [leftWbsData]="wbsStaticData"
    [actions]="templateHubActions"
    #hub
  >
  </is-igx-hub>
</div>

<is-igx-hub
  #customTemplate
  *ngIf="structures.length > 0"
  [mappingData]="templateFormHub"
  [leftWbsData]="structures"
  [actions]="templateFormHubActions"
>
</is-igx-hub>

<is-igx-dialog #form>
  <header>WBSの追加</header>
  <input type="text" [(ngModel)]="addWbsName" />
  <div>
    <button igxButton="outlined" (click)="form.close()">キャンセル</button>
    <button igxButton="outlined" (click)="addStructure()">追加</button>
  </div>
</is-igx-dialog>
