<ura-idea-header></ura-idea-header>
<div class="app-area">
  <div *ngIf="loading">
    <section *ngIf="!organizationChartLoading">
      <app-organization-selected-form
        [(selectedValue)]="selectedOrganization"
      ></app-organization-selected-form>
      <div style="display: flex; gap: 1rem">
        <button igxButton="outlined" (click)="onSearchOrganization()">組織情報取得</button>
        <button igxButton="outlined" (click)="onMigrationContractEmployment()">
          雇用契約を移行する
        </button>
      </div>
    </section>

    <is-igx-organization-chart
      *ngIf="organizationChartLoading"
      #organizationChartComp
      title="役職設定"
      [orgData]="organizationChart"
      [structures]="structures"
      [mappingData]="organizationChartSetting"
      (menuClick)="onMenuClick($event)"
    ></is-igx-organization-chart>
    <is-igx-dialog #regOPMasterDialog [width]="550">
      <section>
        <app-register-official-position-master
          #registerOfficialPositionMasterComp
          [companyId]="selectedBlockId"
          (createdOfficialPosition)="createdOfficialPosition($event)"
          (cancel)="onOfficialPositionMasterCancel()"
        ></app-register-official-position-master></section
    ></is-igx-dialog>
  </div>
</div>
