import { Component, OnInit, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  FrontEndSettingItem,
  IsIgxDialogComponent,
  IsIgxHubComponent,
  IsIgxOrganizationChartComponent,
  IsWbsService,
  WbsFrontend,
} from '@interserve/igx-angular'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { UraIdeaHeaderComponent } from '../components/uraIdeaHeader/ura-idea-header.component'
import { OrganizationSelectedFormComponent } from '../components/organization-selected-form/organization-selected-form.component'
import { ActivatedRoute, Router } from '@angular/router'
import { RegisterOfficialPositionMasterComponent } from '../register-official-position-master/register-official-position-master.component'
import { IsUtilityService } from '@interserve/angular'
import { IgxButtonModule } from '@infragistics/igniteui-angular'
@Component({
  selector: 'app-official-position-editor',
  standalone: true,
  imports: [
    CommonModule,
    IsIgxOrganizationChartComponent,
    IsIgxHubComponent,
    UraIdeaHeaderComponent,
    OrganizationSelectedFormComponent,
    RegisterOfficialPositionMasterComponent,
    IsIgxDialogComponent,
    IgxButtonModule,
  ],
  templateUrl: './official-position-editor.component.html',
  styleUrl: './official-position-editor.component.scss',
})
export class OfficialPositionEditorComponent implements OnInit {
  constructor(
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private route: ActivatedRoute,
    private isWbsService: IsWbsService,
    private router: Router,
    private isUtilityService: IsUtilityService
  ) {}
  // #region loading
  public loading: boolean = false
  public organizationChartLoading: boolean = false
  public apiExecuting: boolean = false
  // #endregion

  //#region setting

  @ViewChild('officialTemplateSelectHub', { static: false })
  public officialTemplateSelectHub?: IsIgxHubComponent

  public organizationChartSetting?: WbsFrontend
  @ViewChild('organizationChartComp', { static: false })
  public organizationChartComp?: IsIgxOrganizationChartComponent
  public structures: any[] = []
  public organizationChart: any = []

  @ViewChild('registerOfficialPositionMasterComp', { static: false })
  public registerOfficialPositionMasterComp?: RegisterOfficialPositionMasterComponent

  @ViewChild('regOPMasterDialog', { static: false })
  public regOPMasterDialog?: IsIgxDialogComponent

  private frontEndCd: 'official-position_editor-type-namecard' | 'official-position_editorV3' =
    'official-position_editorV3'
  // #endregion

  // #region apiInfo
  public apiInfo?: any
  // #endregion

  // #region menu
  selectedBlockId: string = ''
  selectedBlockOrgTypeCd?: string
  // #endregion

  selectedOrganization: any = undefined
  organizationChartId: string = ''
  customerId?: string
  async ngOnInit(): Promise<void> {
    // queryStringからfrontEndCdを取得
    const queryParams = this.route.snapshot.queryParamMap
    const frontEndCd = queryParams.get('frontEndCd')
    if (frontEndCd) {
      this.frontEndCd = frontEndCd as
        | 'official-position_editor-type-namecard'
        | 'official-position_editorV3'
    }
    await this.getSettings()
    this.loading = true

    // routeからidを取得
    const organizationId = this.route.snapshot.paramMap.get('organizationChartId')
    if (organizationId) {
      this.searchOrganizationInfo(organizationId)
    }
    // routeからcustomerIdを取得
    const customerId = this.route.snapshot.paramMap.get('customerId')
    if (customerId) {
      this.customerId = customerId
    }
  }

  private async getSettings(): Promise<void> {
    const orgChartSetting = await this.backendOrganizationLeafService.getFrontEndSettingsItem(
      'organization',
      this.frontEndCd
    )

    const address = this.backendOrganizationLeafService.getFrontEndSettingsItem('myidea', 'address')

    const basePath = this.backendOrganizationLeafService.getFrontEndSettingsItem(
      'myidea',
      'basePaths'
    )
    // TODO: dataが存在しない場合の処理
    this.organizationChartSetting = orgChartSetting.data
    const apiInfos = await Promise.all([basePath, address])
    const basePathInfo = apiInfos[0].data
    const addressInfo = apiInfos[1].data
    this.apiInfo = {
      basePathInfo,
      addressInfo,
    }
  }
  onSearchOrganization() {
    const organizationChartId = this.selectedOrganization?.organizationId
    // routeにidを設定
    this.router.navigate(['/office-position/editor', organizationChartId])
    this.organizationChartId = organizationChartId
    this.searchOrganizationInfo(organizationChartId)
  }

  async onMigrationContractEmployment() {
    if (this.apiExecuting) {
      return
    }
    this.apiExecuting = true

    const organizationChartId = this.selectedOrganization?.organizationId
    const data = await this.backendOrganizationLeafService.postMigrationContractEmployment(
      organizationChartId
    )
    this.apiExecuting = false
    if (data.resultCode === 0) {
      alert('正常に処理が完了しました。')
    } else {
      alert(data.data)
    }
  }
  async searchOrganizationInfo(organizationChartId: string): Promise<void> {
    if (organizationChartId) {
      if (this.apiExecuting) {
        return
      }
      this.apiExecuting = true
      const req1 = this.backendOrganizationLeafService.getOrganizationStructure(organizationChartId)
      const req2 = this.backendOrganizationLeafService.getOrganizationChart(organizationChartId)
      this.apiExecuting = false
      const returns = await Promise.all([req1, req2])
      this.structures = returns[0].data
      this.organizationChart = [returns[1].data]

      const initializedOrg = this.isWbsService.orgChartDataInit(
        structuredClone(this.organizationChart),
        structuredClone(this.structures),
        'nodeObjects',
        'levelCd'
      )
      this.organizationChart = initializedOrg
      this.organizationChartId = organizationChartId
      this.organizationChartLoading = true
    }
  }
  onMenuClick(event: { item: string; selectedBlock: any }) {
    const { item, selectedBlock } = event
    const selectedBlockId = selectedBlock.id
    const selectedBlockOrgTypeCd = selectedBlock.profiles.organizationTypeCd
    this.selectedBlockId = ''
    this.selectedBlockOrgTypeCd = ''
    this.isUtilityService.executeFunction(this, item, selectedBlockId, selectedBlockOrgTypeCd)
  }

  registerEmploymentContract(organizationId: any, selectedBlockOrgTypeCd: string) {
    if (selectedBlockOrgTypeCd !== 'organization_type-company')
      return alert('会社以外は登録できません。')

    this.router.navigate(['/register-employment-contract', organizationId])
  }

  registerEmploymentContractNameCard(organizationId: any, selectedBlockOrgTypeCd: string) {
    if (selectedBlockOrgTypeCd !== 'organization_type-company')
      return alert('会社以外は登録できません。')

    if (!this.customerId) return alert('顧客IDが存在しません')

    this.router.navigate([
      '/register-employment-contract-for-customer',
      organizationId,
      this.customerId,
    ])
  }

  registerOfficialPositionMaster(selectedBlockId: string, selectedBlockOrgTypeCd: string) {
    if (selectedBlockOrgTypeCd !== 'organization_type-company')
      return alert('会社以外は登録できません。')

    this.selectedBlockId = selectedBlockId
    this.regOPMasterDialog?.open()
    this.registerOfficialPositionMasterComp?.open(this.selectedBlockId)
  }

  createdOfficialPosition(event: any) {
    this.regOPMasterDialog?.close()
  }

  async linkOfficialPositionToOrganization(organizationId: any) {
    this.router.navigate([
      '/link-official-position-to-organization',
      this.organizationChartId,
      organizationId,
    ])
  }

  linkCustomerToOfficialPosition(organizationId: any) {
    console.log(organizationId)
    this.router.navigate([
      '/link-customer-to-official-position',
      this.organizationChartId,
      organizationId,
    ])
  }
  assignCustomer(organizationId: any, selectedBlockOrgTypeCd: string) {
    if (selectedBlockOrgTypeCd === 'organization_type-company')
      return alert('会社では登録できません。')

    this.router.navigate(['/assign-customer-to-company', this.organizationChartId, organizationId])
  }

  assignPersonToCompany(organizationId: any, selectedBlockOrgTypeCd: string) {
    if (selectedBlockOrgTypeCd !== 'organization_type-company')
      return alert('会社以外は登録できません。')
    this.router.navigate(['/assign-person-to-company', this.organizationChartId, organizationId])
  }
  onOfficialPositionMasterCancel() {
    this.regOPMasterDialog?.close()
  }
}
