import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import {
  FrontEndSettingItem,
  IsIgxDialogComponent,
  IsIgxHubComponent,
  IsWbsService,
  WbsFrontend,
} from '@interserve/igx-angular'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { EditableTextFieldComponent } from '../components/editable-text-field/editable-text-field.component'
import { IgxButtonModule } from '@infragistics/igniteui-angular'
import { FormsModule } from '@angular/forms'
@Component({
  selector: 'app-register-official-position-master',
  standalone: true,
  imports: [
    CommonModule,
    IsIgxHubComponent,
    EditableTextFieldComponent,
    EditableTextFieldComponent,
    IgxButtonModule,
    IsIgxDialogComponent,
    FormsModule,
  ],
  templateUrl: './register-official-position-master.component.html',
  styleUrl: './register-official-position-master.component.scss',
})
export class RegisterOfficialPositionMasterComponent {
  apiExecuting: boolean = false
  constructor(
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private isWbsService: IsWbsService
  ) {}
  @ViewChild('hub', { static: false }) hub?: IsIgxHubComponent
  @ViewChild('editField', { static: false }) editField?: EditableTextFieldComponent
  @ViewChild('customHub', { static: false }) customHub?: IsIgxHubComponent
  @ViewChild('form', { static: false }) form?: IsIgxDialogComponent
  @Output()
  createdOfficialPosition = new EventEmitter<any>()
  @Output() cancel = new EventEmitter<any>()
  @Input() companyId: string = ''
  createdOfficialPositionData: any = undefined
  hubSetting?: WbsFrontend
  customHubDataList: any[] = []
  addWbsName: string = ''
  templateHubSetting: WbsFrontend = {
    layout: {
      header: {
        label: '要素を追加',
      },
      footer: {
        buttons: [
          {
            key: 'cancel',
            label: 'キャンセル',
            color: 'cancel',
            action: 'close',
          },
          {
            key: 'select',
            name: 'save-button',
            label: '選択',
            color: 'primary',
            action: 'select',
          },
        ],
      },
      leftWbs: {
        type: 'grid',
      },
      leftWbsRules: {
        childDataKey: 'children',
        primaryKey: 'frontEndId',
        hideHeader: false,
        rowDraggable: false,
        rowSelection: 'multiple',
        canAddElement: true,
        noSelectionWbsTypes: ['plusButton', 'company'],
        fields: [
          {
            name: 'name',
            label: '名称',
            width: '100%',
            dataType: 'string',
          },
        ],
      },
    },
  }
  templateFormHubActions = {
    onClickPlus: (event: any) => {
      this.form?.open()
    },
    select: (event: any) => {
      const selected = event.leftWbs.selected
      if (selected.length === 0) {
        alert('追加するWBSを選択してください。')
        return
      }

      this.createdOfficialPositionData = this.customHubDataList
      this.hub?.close()
      this.customHub?.close()
    },
  }

  loading = false

  officialPositionTemplateList: { id: string; name: string }[] = []
  templateHubActions = {
    close: (event: any) => {
      this.cancel.emit()
      this.hub?.close()
    },
    select: async (event: any) => {
      const selectedTemplateId = event.leftWbs.selected[0].id
      if (!selectedTemplateId) {
        return
      }
      if (selectedTemplateId === 'op_template_custom') {
        this.customHub?.open()
      } else {
        const templateDetail =
          await this.backendOrganizationLeafService.getOfficialPositionTemplateDetail(
            selectedTemplateId
          )

        this.createdOfficialPositionData = templateDetail.data
        this.close()
      }
    },
  }
  async ngOnInit(): Promise<void> {
    const req = this.backendOrganizationLeafService.getOfficialPositionTemplate(
      'officialPositionTemplateList'
    )
    const hubSetting = this.backendOrganizationLeafService.getFrontEndSettingsItem(
      'organization',
      'official-position_editor_hubV3'
    )

    const returns = await Promise.all([req, hubSetting])

    this.officialPositionTemplateList = this.isWbsService.addFrontEndId(returns[0].data)
    this.hubSetting = returns[1].data

    this.loading = true
  }

  async open(companyId?: string) {
    if (companyId) {
      const officialPositionList = await this.getOfficialPositionList(companyId)
      console.log(officialPositionList)
      if (officialPositionList?.length) {
        this.createdOfficialPositionData = officialPositionList
      } else {
        this.hub?.open()
      }
    } else {
    }
  }

  close() {
    this.hub?.close()
  }

  async getOfficialPositionList(companyId: string) {
    const res = await this.backendOrganizationLeafService.getOfficialPositionList(companyId)
    return res?.data
  }
  createOfficialPosition() {
    this.createdOfficialPosition.emit(this.createdOfficialPositionData)
  }

  async onOfficialPositionCreated() {
    // api実行中は何もしない
    if (this.apiExecuting) {
      return
    }
    if (this.companyId && this.editField?.validate()) {
      this.apiExecuting = true
      const res = await this.backendOrganizationLeafService.postOfficialPositionDeleteInsert(
        this.companyId,
        this.createdOfficialPositionData
      )
      this.apiExecuting = false
      if (!res.resultCode) {
        alert('登録しました')
        this.createdOfficialPosition.emit(this.createdOfficialPositionData)
      }
    } else {
      console.log('invalid')
    }
  }
  onCancel() {
    this.cancel.emit()
  }

  addStructure() {
    this.customHubDataList.push({
      id: `${crypto.randomUUID()}`,
      name: this.addWbsName,
      profiles: null,
    })
    this.customHubDataList = this.isWbsService.addFrontEndIds(this.customHubDataList)
    this.customHub?.close()
    this.customHub?.open()

    this.addWbsName = ''
    this.form?.close()
  }
}
