import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { IsIgxOrganizationChartComponent, IsWbsService, WbsFrontend } from '@interserve/igx-angular'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-link-official-position-organization',
  standalone: true,
  imports: [CommonModule, IsIgxOrganizationChartComponent],
  templateUrl: './link-official-position-organization.component.html',
  styleUrl: './link-official-position-organization.component.scss',
})
export class LinkOfficialPositionOrganizationComponent {
  constructor(
    private route: ActivatedRoute,
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private isWbsService: IsWbsService
  ) {}

  testFlg = environment.test
  loading = false
  apiExecuting = false
  organizationChartId: string = ''
  organizationId: string = ''
  organizationChart: any = []
  hubData: any = []
  mappingDataHub: WbsFrontend = {
    layout: {
      header: {
        label: '要素を追加',
      },
      footer: {
        buttons: [
          {
            key: 'cancel',
            label: 'キャンセル',
            color: 'cancel',
            action: 'close',
          },
          {
            key: 'select',
            name: 'save-button',
            label: '選択',
            color: 'primary',
            action: 'select',
          },
        ],
      },
      leftWbs: {
        type: 'grid',
      },
      leftWbsRules: {
        childDataKey: 'nodeObject',
        primaryKey: 'frontEndId',
        hideHeader: false,
        rowSelection: 'multiple',
        noSelectionWbsTypes: ['plusButton'],
        canAddElement: false,
        fields: [
          {
            name: 'name',
            label: '名称',
            dataType: 'string',
          },
        ],
      },
    },
  }
  mappingData: WbsFrontend = {
    layout: {
      leftWbs: {
        type: 'wbs',
      },
      leftWbsRules: {
        primaryKey: 'frontEndId',
        rowDraggable: true,
        nonStructure: true,
      },
      rightWbs: {
        type: 'wbs',
        footer: {
          buttons: [
            {
              key: 'select',
              label: '追加',
              color: 'primary',
              action: 'add',
            },
          ],
        },
      },
      rightWbsRules: {
        primaryKey: 'frontEndId',
        rowDraggable: true,
      },
    },
  }

  async ngOnInit(): Promise<void> {
    this.route.params.subscribe(async (params) => {
      const organizationChartId = params['organizationChartId']
      const organizationId = params['organizationId']
      this.organizationChartId = organizationChartId
      this.organizationId = organizationId
      await this.getOfficePositionOrganizationChart()
      this.getOfficePositionList()
      this.loading = true
    })
  }
  async getOfficePositionOrganizationChart() {
    const res = await this.backendOrganizationLeafService.getOfficialPosition(this.organizationId)
    this.organizationChart = [this.isWbsService.addFrontEndId(res.data)]
  }

  async getOfficePositionList() {
    const res = await this.backendOrganizationLeafService.getOfficialPositionList(
      this.organizationChartId
    )
    this.hubData = res.data
  }

  onReGisterOrganizationChart({ addData, orgData }: any) {
    // api実行中は何もしない
    if (this.apiExecuting) {
      return
    }
    this.apiExecuting = true
    this.backendOrganizationLeafService
      .postOfficialPositionRelationUpdate(this.organizationId, orgData[0])
      .then((res) => {
        console.log(res)
        if (!res.resultCd) {
          alert('登録しました')
        }
        this.apiExecuting = false
      })
      .catch((err) => {
        console.log(err)
        this.apiExecuting = false
      })
  }
}
