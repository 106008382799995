import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IsIgxOrganizationChartComponent, IsWbsService, WbsFrontend } from '@interserve/igx-angular'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { ActivatedRoute } from '@angular/router'
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-assign-person-to-company',
  standalone: true,
  imports: [CommonModule, IsIgxOrganizationChartComponent],
  templateUrl: './assign-person-to-company.component.html',
  styleUrl: './assign-person-to-company.component.scss',
})
export class AssignPersonToCompanyComponent {
  loading: boolean = true
  apiExecuting: boolean = false
  organizationChart: any[] = []
  hubData: any[] = []
  organizationChartId: string = ''
  organizationId: string = ''
  mappingData: WbsFrontend | undefined
  mappingDataHub: WbsFrontend | undefined
  constructor(
    private route: ActivatedRoute,
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private isWbsService: IsWbsService
  ) {}

  async ngOnInit(): Promise<void> {
    const organizationId = this.route.snapshot.paramMap.get('organizationId')
    this.organizationId = organizationId || ''
    const organizationChartId = this.route.snapshot.paramMap.get('organizationChartId')
    this.organizationChartId = organizationChartId || ''
    this.getCustomerCompany()
    const customerContractEmployment = await this.getCustomerContractEmployment()

    //TODO: FrontEndIdを変更する必要がある
    const data = await this.backendOrganizationLeafService.getFrontEndSettingsItem(
      'organization',
      'link-customer-to-official-position-hubV3'
    )
    this.mappingDataHub = data.data
    this.mappingData = {
      layout: {
        leftWbs: {
          type: 'wbs',
        },
        leftWbsRules: {
          primaryKey: 'frontEndId',
          rowDraggable: true,
          nonStructure: true
        },
        rightWbs: {
          type: 'wbs',
          footer: {
            buttons: [
              {
                key: 'select',
                label: '追加',
                color: 'primary',
                action: 'add',
              }
            ]
          }
        },
        rightWbsRules: {
          primaryKey: 'frontEndId',
          rowDraggable: true,
        },
      },
    }

    this.hubData = customerContractEmployment
    this.loading = false
  }

  async getCustomerCompany(): Promise<any> {
    this.organizationChart = []
    const ret = await this.backendOrganizationLeafService.getCustomerCompany(
      this.organizationChartId
    )
    this.organizationChart = [this.isWbsService.addFrontEndId(ret.data)]
  }
  async getCustomerContractEmployment() {
    const res = await this.backendOrganizationLeafService.getCustomerContractEmployment(
      this.organizationChartId
    )
    return res.data
  }
  async onReGisterOrganizationChart({ addData, orgData }: any) {
    // api実行中は何もしない
    if (this.apiExecuting) {
      return
    }
    this.apiExecuting = true
    const ret = await this.backendOrganizationLeafService.postCustomerCompany(orgData[0])
    if (!ret.resultCd) {
      alert('登録しました')
      this.getCustomerCompany()
    }
    this.apiExecuting = false
  }
}
