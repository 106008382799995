<div class="editable-text-fields">
  <div *ngFor="let item of items; let i = index" class="editable-area">
    <div class="editable-item" [ngClass]="{ disabled: checkDisabled(item) }">
      @if(item.profiles?.editable===false){
      <input style="cursor: not-allowed" disabled type="text" [value]="item.name" />
      } @else {
      <input
        [disabled]="checkDisabled(item)"
        type="text"
        [(ngModel)]="item.name"
        (dblclick)="item.disabled = false"
        (blur)="item.disabled = true"
      />
      }
    </div>
    <button (click)="onAddButton(item, i)" *ngIf="addItemFlg">+</button>
  </div>
</div>
