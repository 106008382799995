<div class="app-area">
  <section *ngIf="loading">
    <is-igx-organization-chart
      title="組織に役職を紐づける"
      [orgData]="organizationChart"
      [mappingData]="mappingData"
      [mappingDataHub]="mappingDataHub"
      [hubData]="hubData"
      (onReGisterOrganizationChart)="onReGisterOrganizationChart($event)"
    ></is-igx-organization-chart>
  </section>
</div>
<pre *ngIf="testFlg">
  <div>{{organizationChart|json}}</div>
</pre>
