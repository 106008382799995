import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { IsIgxOrganizationChartComponent, IsWbsService, WbsFrontend } from '@interserve/igx-angular'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'

@Component({
  selector: 'app-assign-customer',
  standalone: true,
  imports: [CommonModule, IsIgxOrganizationChartComponent],
  templateUrl: './assign-customer.component.html',
  styleUrl: './assign-customer.component.scss',
})
export class AssignCustomerComponent {
  private apiExecuting: boolean = false
  organizationChart: any[] = []
  hubData: any[] = []
  organizationChartId: string = ''
  organizationId: string = ''
  mappingDataHub: WbsFrontend = {
    layout: {
      header: {
        label: '要素を追加',
      },
      footer: {
        buttons: [
          {
            key: 'cancel',
            label: 'キャンセル',
            color: 'cancel',
            action: 'close',
          },
          {
            key: 'select',
            name: 'save-button',
            label: '選択',
            color: 'primary',
            action: 'select',
          },
        ],
      },
      leftWbs: {
        type: 'grid',
      },
      leftWbsRules: {
        childDataKey: 'nodeObject',
        primaryKey: 'frontEndId',
        hideHeader: false,
        rowSelection: 'multiple',
        noSelectionWbsTypes: ['plusButton'],
        canAddElement: false,
        fields: [
          {
            name: 'name',
            label: '名称',
            dataType: 'string',
          },
        ],
      },
    },
  }
  mappingData: WbsFrontend = {
    layout: {
      leftWbs: {
        type: 'wbs',
      },
      leftWbsRules: {
        primaryKey: 'frontEndId',
        rowDraggable: true,
        nonStructure: true,
      },
      rightWbs: {
        type: 'wbs',
        footer: {
          buttons: [
            {
              key: 'select',
              label: '追加',
              color: 'primary',
              action: 'add',
            },
          ],
        },
      },
      rightWbsRules: {
        primaryKey: 'frontEndId',
        rowDraggable: true,
      },
    },
  }

  constructor(
    private route: ActivatedRoute,
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private isWbsService: IsWbsService
  ) {}

  async ngOnInit(): Promise<void> {
    const organizationId = this.route.snapshot.paramMap.get('organizationId')
    this.organizationId = organizationId || ''
    const organizationChartId = this.route.snapshot.paramMap.get('organizationChartId')
    this.organizationChartId = organizationChartId || ''
    this.getCustomerOrganization()
    const customerCompanyFlat = await this.getCustomerCompanyFlat()
    this.hubData = this.isWbsService.addFrontEndId(customerCompanyFlat)
  }
  async getCustomerOrganization() {
    this.organizationChart = []
    const ret = await this.backendOrganizationLeafService.getCustomerOrganization(
      this.organizationId
    )
    this.organizationChart = [this.isWbsService.addFrontEndId(ret.data)]
  }

  async getCustomerCompanyFlat() {
    const res = await this.backendOrganizationLeafService.getCustomerCompanyFlat(
      this.organizationChartId
    )
    return res.data
  }

  async onReGisterOrganizationChart({ addData, orgData }: any) {
    console.log(orgData)
    if (this.apiExecuting) {
      return
    }
    this.apiExecuting = true
    const ret = await this.backendOrganizationLeafService.postCustomerOrganization(
      this.organizationId,
      orgData[0]
    )
    if (!ret.resultCd) {
      alert('登録しました')
      this.getCustomerOrganization()
    }
    this.apiExecuting = false
  }
}
