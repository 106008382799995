import { Component, ViewChild } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { BackendOrganizationLeafService } from '../../../services/apis/backend-organization-leaf.service'
import { EditableTextFieldComponent } from '../components/editable-text-field/editable-text-field.component'
import { IsApiService } from '@interserve/angular'

@Component({
  selector: 'app-organization-structure-edit',
  standalone: true,
  imports: [CommonModule, EditableTextFieldComponent],
  templateUrl: './organization-structure-edit.component.html',
  styleUrl: './organization-structure-edit.component.scss',
})
export class OrganizationStructureEditComponent {
  organizationChartId: string | null = null
  structures: any[] = []
  apiExecuting: boolean = false
  @ViewChild('editField', { static: false }) editField?: EditableTextFieldComponent
  constructor(
    private route: ActivatedRoute,
    private backendOrganizationLeafService: BackendOrganizationLeafService,
    private isApiService: IsApiService
  ) {}
  async ngOnInit(): Promise<void> {
    this.organizationChartId = this.route.snapshot.paramMap.get('organizationChartId')
    this.initOrganizationStructure()
  }
  async initOrganizationStructure() {
    if (!this.organizationChartId) return
    const ret = await this.backendOrganizationLeafService.getOrganizationStructure(
      this.organizationChartId
    )
    const structures = this.isApiService.checkApiReturnData(ret)
    if (!structures) return
    this.structures = structures
  }
  async onOfficialPositionCreated() {
    if (this.editField?.validate()) {
      // api実行中は何もしない
      if (this.apiExecuting) return
      this.apiExecuting = true
      const data = this.editField?.items
      if (!this.organizationChartId) return
      const ret = await this.backendOrganizationLeafService.postOrganizationStructureDeleteInsert(
        this.organizationChartId,
        data
      )
      this.apiExecuting = false
      alert('保存しました')
      this.initOrganizationStructure()
    }
  }
}
