import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  selector: 'ura-idea-header',
  template: ` <header class="c-header" [class.ura]="ura"></header> `,
  styleUrl: './ura-idea-header.component.scss',
})
export class UraIdeaHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
  ura: boolean = true
}
