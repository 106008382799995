export const environment = {
  production: false,
  test: false,
  api: {
    backend: {
      organizationLeafApi: '//backend-organizationleafapi.backend.out.nakamenosakura.com',
      macroApi: '//backend-macroapi.backend.out.nakamenosakura.com',
    },
    myidea: {
      macroApi: '//myidea-macroapi.backend.out.nakamenosakura.com',
      indexCoreApi: '//indexcore-myidea.isbe.out.nakamenosakura.com',
    },
    businessEngine: {
      wbsManagementOutsideApi: '//me-wbsmanagementoutsideapi.isbe.out.nakamenosakura.com',
    },
  },
  ui: {
    backend: {
      uraIdea: '//uraidea.backend.out.nakamenosakura.com',
    },
  },
}
