import { HttpClient } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { lastValueFrom } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly backendMacroApiBaseUrl = environment.api.backend.macroApi
  private readonly myideaIndexCoreApiBaseUrl = environment.api.myidea.indexCoreApi
  private readonly businessEngineWbsManagementOutsideApiBaseUrl =
    environment.api.businessEngine.wbsManagementOutsideApi

  constructor(private http: HttpClient) {}

  async loginV3ForToken(token: string): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }

    const loginUrl = `${this.backendMacroApiBaseUrl}/api/v3/Accounts/BackendLogin/Token`
    return lastValueFrom(this.http.post(loginUrl, {}, { headers }))
  }
  async indexDns(organizationCd: string, customerCd: string): Promise<any> {
    const query = `?fqdn=authority.${organizationCd}.${customerCd}&createIfNotExists=true`
    const indexDnsPath = `${this.myideaIndexCoreApiBaseUrl}/api/indexDns${query}`
    return lastValueFrom(this.http.get(indexDnsPath))
  }

  async wbsManagement(fileSystemId: string, jsonType: number): Promise<any> {
    const wbsManagementPath = `${this.businessEngineWbsManagementOutsideApiBaseUrl}/api/wbs/${fileSystemId}?jsonType=${jsonType}`
    return lastValueFrom(this.http.get(wbsManagementPath))
  }
}

export default AuthApiService
